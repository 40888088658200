import React, { createContext, useReducer, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as actions from '../actions/StepActions';
import { DLG_DEBUG_STATE_EDITOR_ENABLED, DLG_DEBUG_STATE_EDITOR_DATA } from '../components/atoms/StateEditor';

export enum Step {
  YOUR_QUOTE = 0,
  VEHICLE_DETAILS = 1,
  BUSINESS_DETAILS = 2,
  IMPORTANTINFO = 3,
  REVIEW = 4,
  PAYMENT = 5,

  // ToDo: add and repalce payment for stripeStripe
  // PROCESSING = 5,
  // CANCEL = 6,
  // FAILEDPAYMENT = 7,
}

interface ISteps {
  label: string;
  url: string;
  hidden?: boolean;
  showStepCountLabel?: boolean;
}

export const steps = [
  {
    label: 'Your Quote',
    url: '/',
  },
  {
    label: 'Vehicle Details',
    url: '/vehicleDetails',
  },
  {
    label: 'Business Details',
    url: '/businessDetails',
  },
  {
    label: 'Important Information',
    url: '/importantInfo',
  },
  {
    label: 'Review',
    url: '/review',
  },
  {
    label: 'Payment',
    url: '/payment',
  },
  // ToDo: Add and replace payment for Stripe
  // {
  //   label: 'Payment',
  //   url: '/processing',
  // },
  // {
  //   label: 'Cancel Payment',
  //   url: '/cancel',
  //   hidden: true,
  //   showStepCountLabel: false,
  // },
  // {
  //   label: 'Failed Payment',
  //   url: '/failedPayment',
  //   hidden: true,
  //   showStepCountLabel: false,
  // },
  {
    label: 'All Sorted',
    url: '/all-sorted',
    hidden: true,
    showStepCountLabel: false,
  },
  {
    label: 'Timeout',
    url: '/timeout',
    hidden: true,
    showStepCountLabel: false,
  },
];

/* StateEditor component initialisation  */
const debugEnabled: string | null = localStorage.getItem(DLG_DEBUG_STATE_EDITOR_ENABLED);
const debugData = debugEnabled === 'true' ? localStorage.getItem(DLG_DEBUG_STATE_EDITOR_DATA) : null;

export const quoteDataFleet = {
  coverOptions: null,
  moreThanFiftyVehicles: '',
  breakdownCoverLocation: '',
  vehicleRepairLocation: '',
  coverType: '',
  vehicleCount: null,
  quoteTotal: 0,
  vehicleId: '',
  coverStartDate: null,
  expires: undefined,
  timestamp: undefined,
  user_id: undefined,
};

export const quoteData = {
  timeout: false,
  quote: '',
  source: '',
  emailAddress: '',
  phoneNumber: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  addressLine4: '',
  addressLine5: '',
  // ToDo: Remove billing address for Stripe
  billingAddressLine1: '',
  billingAddressLine2: '',
  billingAddressLine3: '',
  billingAddressLine4: '',
  billingAddressLine5: '',
  billingAddressPostCode: '',
  postcode: '',
  vehicleAge: 0,
  productName: '',
  coverPrice: null,
  policyStartDate: undefined,
  policyEndDate: undefined,
  partnerTitle: '',
  partnerInitial: '',
  partnerSurname: '',
  automaticRenewal: 'Yes',
  coverType: '',
  quoteTotal: 0,
  policyType: '',
  quoteId: null,
  quote_id: '',
  quoteHash: '',
  //FROM REARCH OLD
  coverOptions: null,
  moreThanFiftyVehicles: '',
  breakdownCoverLocation: '',
  vehicleRepairLocation: '',
  vehicleCount: null,
  vehicleId: '',
  coverStartDate: null,
  expires: undefined,
  timestamp: undefined,
  user_id: undefined,
  vehicles: [],
  partnerizeRef: null,
  checkoutId: '',
};

export const businessDetailsData = {
  businessCompanyName: '',
  businessContactName: '',
  businessContactSurname: '',
  businessContactNumber: '',
  businessContactEmail: '',
  secondPointOfContact: false,
  secondBusinessContactName: '',
  secondBusinessContactSurname: '',
};

// ToDO: Remove paymentData for Stripe
export const paymentData = {
  cardholdersName: '',
  cardNumber: '',
  cardType: '',
  expiryDate: null,
};

// ToDo: Remove summaryData for Stripe
export const summaryData = {
  paymentSuccessful: null,
};

export const initialNavStepperData = {
  ...quoteData,
  // ToDo: Remove paymentData & summaryData for Stripe
  ...paymentData,
  ...summaryData,
  ...businessDetailsData,
  options: null,
};

export interface IBusinessDetailsAddress {
  firstLineOfAddress: string;
  secondLineOfAddress: string;
  town: string;
  county: string;
  postcode: string;
}

export interface VehicleData {
  reactIndex: number;
  reg: string;
  make: string;
  model: string;
  year: number | string | null;
  complete: boolean;
}

export interface IStepData {
  options: any;
  quote: string | number;
  quote_id: string | number;
  source: string;
  emailAddress: string;
  phoneNumber: number | string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  addressLine5: string;
  // ToDo: Remove billingAddress for Stripe
  billingAddressLine1: string;
  billingAddressLine2: string;
  billingAddressLine3: string;
  billingAddressLine4: string;
  billingAddressLine5: string;
  billingAddressPostCode: string;
  postcode: string;
  productName: string;
  coverPrice: null | number;
  policyStartDate: Date | string | null | undefined;
  policyEndDate: Date | string | null | undefined;
  partnerTitle: string;
  partnerInitial: string;
  partnerSurname: string;
  coverType: string;
  policyType: string;
  automaticRenewal: string;
  quoteId: null;
  // ToDo: remove Card data (next 4 lines) for Stripe
  cardholdersName: string;
  cardNumber: number | string;
  cardType: string;
  expiryDate: Date | null;
  quoteTotal: number;
  // ToDo: remove paymentSuccessful for Stripe
  paymentSuccessful: boolean | null;
  quoteHash: string;
  moreThanFiftyVehicles: string;
  vehicleCount: number | null;
  coverStartDate: Date | string | null;
  vehicles: Array<VehicleData>;
  breakdownCoverLocation: string;
  vehicleRepairLocation: string;
  coverOptions: any;
  businessCompanyName: string;
  businessContactName: string;
  businessContactSurname: string;
  businessContactNumber: string;
  businessContactEmail: string;
  secondPointOfContact: boolean;
  secondBusinessContactName: string;
  secondBusinessContactSurname: string;
  partnerizeRef: string | null;
  checkoutId: string | null;
}

export interface IStep {
  activeStep: number;
  steps: Array<ISteps>;
  data: IStepData;
  loading: boolean;
  showStepper: boolean;
}

const initialState = {
  activeStep: debugData ? JSON.parse(debugData).activeStep : 1,
  steps: steps,
  data: debugData ? JSON.parse(debugData).data : initialNavStepperData,
  loading: true,
  showStepper: true,
  updateLoading: () => {},
  updateQuotes: () => {},
  updateActiveStep: () => {},
  updateData: () => {},
  updateShowStepper: () => {},
  updateVehicleDetails: () => {},
};

export type StepType = {
  activeStep: number;
  steps: Array<ISteps>;
  data: IStepData;
  loading: boolean;
  showStepper: boolean;
  updateLoading: (isLoading: boolean) => void;
  updateQuotes: (quotes) => void;
  updateActiveStep: (newCount: number) => void;
  updateData: (newStepData: IStepData) => void;
  updateShowStepper: (showStepper: boolean) => void;
  updateVehicleDetails: (newVehicleCount: number, newQuoteTotal: number, newVehicleId?: string | null) => void;
};

export const StepContext = createContext<StepType>(initialState);

const reducer = (state: IStep, action: actions.Action) => {
  switch (action.type) {
    case actions.LOADING:
      return { ...state, loading: action.payload };
    case actions.UPDATE_QUOTES:
      return { ...state, data: { ...state.data, coverOptions: action.payload } };
    case actions.UPDATE_ACTIVE_STEP:
      return { ...state, activeStep: action.payload };
    case actions.UPDATE_DATA:
      return { ...state, data: { ...initialNavStepperData, ...action.payload } };
    case actions.UPDATE_SHOW_STEPPER:
      return { ...state, showStepper: action.payload };
    default:
      return state;
  }
};

export const StepProvider = ({ children }: JSX.ElementChildrenAttribute): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const sessionTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const excludedPaths = ['guide', 'timeout', 'processing', 'cancel', 'all-sorted'];
  const excludedTimeout = ['guide', 'timeout', 'all-sorted'];

  const resetSessionTimeout = () => {
    // preventing users from accessing pages without quote data
    if (!excludedPaths.some((path) => window.location.pathname.includes(path)) && state.data?.timeout) {
      resetData();
    }

    sessionTimeoutRef.current = setTimeout(resetData, 15 * 60 * 1000);

    // preventing timeout loop on timeout page
    if (
      excludedTimeout.some((path) => window.location.pathname.includes(path)) &&
      sessionTimeoutRef.current !== null
    ) {
      clearTimeout(sessionTimeoutRef.current);
    }
  };

  const resetData = () => {
    dispatch({ type: actions.LOADING, payload: true });
    dispatch({ type: actions.UPDATE_DATA, payload: { quoteData, timeout: true } });
    if (!state.data?.quote) {
      history.replace('/timeout');
    }
  };

  useEffect(() => {
    resetSessionTimeout();

    return () => {
      if (sessionTimeoutRef.current !== null) {
        clearTimeout(sessionTimeoutRef.current);
      }
    };
  }, [state]);

  return (
    <StepContext.Provider
      value={{
        activeStep: state.activeStep,
        data: state.data,
        steps: state.steps,
        loading: state.loading,
        showStepper: state.showStepper,
        updateLoading: (isLoading: boolean) => dispatch({ type: actions.LOADING, payload: isLoading }),
        updateQuotes: (quotes) => dispatch({ type: actions.UPDATE_QUOTES, payload: quotes }),
        updateActiveStep: (newStep: number) => dispatch({ type: actions.UPDATE_ACTIVE_STEP, payload: newStep }),
        updateData: (newStepData: IStepData) => dispatch({ type: actions.UPDATE_DATA, payload: newStepData }),
        updateShowStepper: (showStepper: boolean) =>
          dispatch({ type: actions.UPDATE_SHOW_STEPPER, payload: showStepper }),
        updateVehicleDetails: (newVehicleCount: number, newQuoteTotal: number, newVehicleId?: string | null) =>
          dispatch({
            type: actions.UPDATE_VEHICLE_DETAILS,
            payload: { vehicleCount: newVehicleCount, quoteTotal: newQuoteTotal, vehicleId: newVehicleId },
          }),
      }}
    >
      {children}
    </StepContext.Provider>
  );
};

export default StepProvider;
