import React, { useContext } from 'react';
import { Box, Grid, Divider } from '@material-ui/core';
import { Typography } from '../Typography';
import { useStyles } from './YourCoverReviewStyles';
import { format } from 'date-fns';
import { StepContext, IStepData, Step, steps } from '../../../contexts/StepContext';
import { LinkPrimary } from '../LinkPrimary';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

interface IYourCoverReview {
  data: IStepData;
}

const YourCoverReview: React.FC<IYourCoverReview> = ({ data }: IYourCoverReview) => {
  const { activeStep, updateActiveStep } = useContext(StepContext);
  const classes = useStyles();
  const history = useHistory();

  const handleEditYourCover = () => {
    updateActiveStep(activeStep - 4);
    history.push(steps[Step.YOUR_QUOTE].url);
  };

  return (
    <Box className={classes.yourCoverReviewContainer}>
      <Box className={classes.yourCoverReviewInnerContainer}>
        <Box>
          <Typography variant="h2">Your cover</Typography>
          <Grid container className={classes.px05}>
            <Grid item xs={6} lg={6}>
              <Typography className={classes.px05}>Total cost for the year:</Typography>
            </Grid>
            <Grid item xs={6} lg={6} className={classes.textRight}>
              <Typography variant="h3">{`£${data.quoteTotal.toFixed(2)}`}</Typography>
            </Grid>
          </Grid>
          <Divider className="divider2-my-1"></Divider>

          <Grid container className="mt1">
            <Grid item xs={5} lg={6}>
              <Typography>Cover type:</Typography>
            </Grid>
            <Grid item xs={7} lg={6} className={classes.textRight}>
              <Typography variant="body1" className="fw-500">
                {data.coverType}
              </Typography>
            </Grid>
          </Grid>

          <Grid container className={classes.px05}>
            <Grid item xs={6} lg={6}>
              <Typography>Start date:</Typography>
            </Grid>
            <Grid item xs={6} lg={6} className={classes.textRight}>
              <Typography variant="body1" className="fw-500">
                {data.coverStartDate ? format(new Date(data.coverStartDate), 'dd/MM/yyyy') : ''}
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={6} lg={6}>
              <Typography>Vehicles covered:</Typography>
            </Grid>
            <Grid item xs={6} lg={6} className={classes.textRight}>
              <Typography variant="body1" className="fw-500">
                {data.vehicleCount} {(data.vehicleCount ?? 0) > 1 ? 'vehicles' : 'vehicle'}
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} lg={8} md={8}>
              <Typography className={clsx(classes.px05, classes.textSizeSmall)}>
                Includes Insurance Premium Tax where applicable. Price available for the next 30 days.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className={classes.bottomHighlight}>
        <LinkPrimary onClick={handleEditYourCover}>Need to change your cover?</LinkPrimary>
      </Box>
    </Box>
  );
};

export default YourCoverReview;
