import React from 'react';
import { Box, Grid, Divider } from '@material-ui/core';
import { Typography } from '../../atoms/Typography';
import { useStyles } from './YourPriceStyles';
import clsx from 'clsx';
import { format } from 'date-fns';
import { IStepData } from '../../../contexts/StepContext';

interface IYourPrice {
  startDate: string | Date | null;
  cover: string;
  price: number;
  vehicleCount: number | null;
  showVehicleWithText?: boolean;
  data: IStepData;
}

const YourPrice: React.FC<IYourPrice> = ({
  startDate,
  cover,
  price,
  vehicleCount,
  showVehicleWithText,
  data,
}: IYourPrice) => {
  const classes = useStyles();

  return (
    <Box className={classes.yourPriceReceiptContainer}>
      <Box className={classes.yourPriceReceiptInnerContainer}>
        <Box>
          <Typography variant="h3">Your cover</Typography>
          <Grid container className={classes.px05}>
            <Grid item xs={6} lg={6}>
              <Typography variant="body1" className={classes.px05}>
                Total cost for the year:
              </Typography>
            </Grid>
            <Grid item xs={6} lg={6} className={classes.textRight}>
              <Typography variant="h3">{`£${price.toFixed(2)}`}</Typography>
            </Grid>
          </Grid>
          <Divider className="divider2-my-1"></Divider>

          <Grid container className="mt1">
            <Grid item xs={5} lg={6}>
              <Typography>Cover type:</Typography>
            </Grid>
            <Grid item xs={7} lg={6} className={classes.textRight}>
              <Typography variant="body1" className="fw-500">
                {cover}
              </Typography>
            </Grid>
          </Grid>

          <Grid container className={classes.px05}>
            <Grid item xs={6} lg={6}>
              <Typography>Start date:</Typography>
            </Grid>
            <Grid item xs={6} lg={6} className={classes.textRight}>
              <Typography variant="body1" className="fw-500">
                {startDate ? format(new Date(startDate), 'dd/MM/yyyy') : ''}
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={6} lg={6}>
              <Typography>Vehicles covered:</Typography>
            </Grid>
            <Grid item xs={6} lg={6} className={classes.textRight}>
              {showVehicleWithText ? (
                <Typography variant="body1" className="fw-500">
                  {`${vehicleCount}  ${vehicleCount !== null && vehicleCount > 1 ? ' vehicles' : 'vehicle'}`}
                </Typography>
              ) : (
                <Typography variant="body1" className="fw-500">
                  {vehicleCount} {vehicleCount === 1 ? 'vehicle' : 'vehicles'}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} lg={8} md={8} className="mt1">
              <Typography className={clsx(classes.px05, classes.textSizeSmall)}>
                Includes Insurance Premium Tax where applicable. Price available for the next 30 days.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default YourPrice;
